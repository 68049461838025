export class EnvironmentBase {
  static google_analytics_id = '';
  static environment = '';
  static palfinger_api_management = '';
  static palfinger_api_management_subs_key = '';
  static palfinger_app_gateway = '';
  static oidc_clientid = '';
  static oidc_scope = '';

  static application_insights_key = '';

  static domainsForApiManagementSubsKey: string[];

  static identityServerAuthConf: {
    whitelistedDomains: string[];
    whitelistedRoutes: string[];
    blacklistedRoutes: string[];
  } = {
    whitelistedDomains: [],
    whitelistedRoutes: [],
    blacklistedRoutes: [],
  };

  static LINKS: {
    SECURITY_TOKEN_SERVICE: string;
    POST_LOGOUT_REDIRECT_URI: string;
    PALDESK_BASEPATH: string;
    STATUS_PALDESK_PAGE: string;
  } = {
    SECURITY_TOKEN_SERVICE: '',
    POST_LOGOUT_REDIRECT_URI: '',
    PALDESK_BASEPATH: '',
    STATUS_PALDESK_PAGE: '',
  };

  static FEATURE_FLAGS: {
    PROJECT_DETAILS_FEATURE_ENABLED: string;
    PROD_RELEASE_V2_1_ENABLED: string;
  } = {
    PROJECT_DETAILS_FEATURE_ENABLED: '',
    PROD_RELEASE_V2_1_ENABLED: '',
  };
}
